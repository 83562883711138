<template>
  <div>
    <e-file-uploader-embed v-model="fileList1" :single="false" :catList="catList" @completed="uploadCompleted1" />
    {{fileList1}}
  </div>
</template>

<script>
export default {
  components: {
    EFileUploaderEmbed: () => import('@/components/common/EFileUploaderEmbed')
  },
  data () {
    return {
      fileList1: [
        {
          guid: '1',
          originalName: '스크린샷, 2021-09-13 08-45-11.png',
          size: '4136833',
          fileId: 'http:/aaaa.ekmtc:990/asdfasdfasd', // 다운로드 URL (서버 호출)
          status: 'complete',
          contentType: 'image/png',
          category: ''
        },
        {
          guid: '2',
          originalName: '스크린샷, 2021-09-13 08-45-11.png',
          size: '4136833',
          fileId: 'http:/aaaa.ekmtc:990/asdfasdfasd', // 다운로드 URL (서버 호출)
          status: 'complete',
          contentType: 'image/png',
          category: ''
        }
      ],
      fileList2: [
        {
          guid: '1',
          originalName: '02 스크린샷, 2021-09-13 08-45-11.png',
          size: '4136833',
          fileId: 'http:/aaaa.ekmtc:990/asdfasdfasd', // 다운로드 URL (서버 호출)
          status: 'complete',
          contentType: 'image/png',
          category: ''
        }
      ],
      // 카테고리가 있는 경우
      catList: [
        { category: 'category1', value: 'value1' },
        { category: 'category2', value: 'value2' }
      ]
    }
  },
  methods: {
    uploadCompleted1 (fileInfo) {
      console.log(fileInfo)
    },
    uploadCompleted2 (fileInfo) {
      console.log(fileInfo)
    }
  }
}
</script>
