var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("e-file-uploader-embed", {
        attrs: { single: false, catList: _vm.catList },
        on: { completed: _vm.uploadCompleted1 },
        model: {
          value: _vm.fileList1,
          callback: function ($$v) {
            _vm.fileList1 = $$v
          },
          expression: "fileList1",
        },
      }),
      _vm._v(" " + _vm._s(_vm.fileList1) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }